import {inject, bindable, LogManager, computedFrom} from 'aurelia-framework';
import Sortable from "sortablejs";
import {Client} from "../../../api/client";
import {DialogService} from 'aurelia-dialog';
import {UniversalEntitySelect} from '../../../dialog/universal-entity-select';
import {ThumbnailLoader} from "../thumbnail-loader/thumbnail-loader";
import {ModuleConfigClient} from "../../../api/module-config-client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(
    Client,
    ModuleConfigClient,
    DialogService,
    EventAggregator,
    BindingSignaler
)
export class MultipleImagesChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    loading = {
        viewItems: false
    };

    constructor(
        client,
        moduleConfigClient,
        dialogService,
        ea,
        signaler
    ) {
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
        this.dialogService = dialogService;
        this.ea = ea;
        this.signaler = signaler;
    }

    attached()
    {
        this.updateDraggableIcons();
        this.subscription = this.ea.subscribe('sio_form_post_submit', response => {
            if (/^media-database/.test(response.config.modelId)) {
                this.signaler.signal('media-item-changed');
            }
        });
    }

    detached()
    {
        this.subscription.dispose();
    }

    configChanged()
    {
        if (!this.config) {
            this.thumbnailLoader = null;

            return;
        }

        this.moduleConfigClient.getModel(this.config.modelId).then(
            model => {
                this.thumbnailLoader = new ThumbnailLoader(this.client, model);
            }
        );
    }

    onEnd(evt)
    {
        this.value.move(evt.oldIndex, evt.newIndex);
    }

    async add()
    {
        if (!this.config.modelId) {
            return;
        }

        let data = await this.dialogService.open({viewModel: UniversalEntitySelect, model: {
            selectModelId: this.config.modelId,
            multiSelect: !!this.config.multiple,
            conditions: this.config.conditions
        }}).whenClosed();

        let items = data.output;

        if (!items || 0 === items.length) {
            return;
        }

        this.value = this.value ? this.value.concat(items) : items;
    }

    remove(itemToRemove)
    {
        const itemToRemoveIndex = this.value.findIndex(item => item === itemToRemove);

        const leftSide = this.value.slice(0, itemToRemoveIndex);
        const rightSide = this.value.slice(itemToRemoveIndex+1);

        this.value = leftSide.concat(rightSide);
    }

    _getControlUID()
    {
        return 'images-choice-' + this.config.fullProperty;
    }

    updateDraggableIcons()
    {
        if (this.config.sortable) {
            let element = document.getElementById(this._getControlUID());

            if (!element) {
                return;
            }

            Sortable.create(element, {
                handle: '.glyphicon-menu-hamburger',
                forceFallback: true,
                onEnd: this.onEnd.bind(this)
            });
        }
    }

    @computedFrom('thumbnailLoader', 'value')
    get viewItems()
    {
        if (!this.value || !this.thumbnailLoader) {
            return [];
        }

        this.loading.viewItems = true;

        return new Promise(async (resolve, reject) => {
            let viewItems = [];

            for (let i = 0, iend = this.value.length; i !== iend; ++i) {
                const item = this.value[i];

                const resolvedItem = {
                    thumbnailUrl: await this.thumbnailLoader.getThumbnailUrl(item),
                    _source: item,
                    _full: await this.thumbnailLoader._loadFullItem(item),
                    id: item.id,
                    modelId: item.modelId
                };

                resolvedItem.title = await this.thumbnailLoader.getTitle(item);
                viewItems.push(resolvedItem);
            }

            this.loading.viewItems = false;

            resolve(viewItems);
        });
    }
}

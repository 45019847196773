import {bindable, inject, LogManager, customElement} from 'aurelia-framework';
import {ChoiceLoader} from "../loader/choice-loader.js";
import {I18N} from "aurelia-i18n";
import * as _ from "lodash";

const logger = LogManager.getLogger('CheckboxesChoiceElement');

@customElement('sio-checkboxes-choice-input')
@inject(
    ChoiceLoader,
    I18N
)
export class CheckboxesChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    constructor(
        choiceLoader,
        i18n
    ) {
        this.choiceLoader = choiceLoader;
        this.i18n = i18n;
    }

    async configChanged(newValue, oldValue)
    {
        this.loading = true;

        if (this.config.translateChoiceLabel == null) {
            this.config.translateChoiceLabel = true;
        }

        const value = [];

        this.choices = await this.choiceLoader.getChoices(this.config).then(choices => {
            let result = [];

            choices.forEach(element => {
                result.push({
                    label: this.config.translateChoiceLabel ? this.i18n.tr(element.label) : element.label,
                    value: element.value,
                    group: element.group
                });

                // avoid unchecked items if value is array of objects
                if (this.value && element.value.id) {
                    _.forEach(this.value, v => {
                        if (v.id && v.id === element.value.id) {
                            value.push(element.value);
                        }
                    });
                }
            });

            this.loading = false;

            result = _.groupBy(result, 'group');

            return result;
        });

        this.value = value.length ? value : (!this.value ? [] : _.castArray(this.value));
    }

    onChange()
    {
        //Somewhat of a hack to trigger value change event and not reimplement the wheel here
        this.value = _.clone(this.value);
    }
}

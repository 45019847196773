import {bindable, customElement, inject} from 'aurelia-framework';
import 'select2';
import 'select2/dist/css/select2.min.css'
import {Client} from "../api/client";
import $ from "jquery";

import "./tag-element.less";

@inject(Element, Client)
@customElement('sio-tag-input')
export class TagElement {

    @bindable({defaultBindingMode: 2}) value;
    @bindable options;

    element;
    client;

    select;

    constructor(element, client) {
        this.element = element;
        this.client = client;
    }

    attached() {
        $(this.select).on('change', this.change.bind(this));
        $(this.select).on('select2:unselect', this.change.bind(this));
    }

    detached() {
        $(this.select).off('change');
        $(this.select).off('select2:unselect');
    }

    bind() {
        const options = {
            language: 'de',
            tags: true,
            tokenSeparators: [','],
            data: this.value,
            selectOnClose: false // Must be false, because otherwise selects even on "Escape" button press
        };

        if (this.options && this.options.hasOwnProperty('dataSource')) {
            options.ajax = {
                delay: 250,
                transport: (params, success, failure) => {
                    let url = this.options.dataSource;

                    if (params && params.data) {
                        url += '?' + $.param(params.data);
                    }

                    this.client.get(url)
                        .then(data => success(data))
                        .catch(error => failure(error))
                }
            };
        } else if (this.options?.modelId != null) {

            options.ajax = {
                delay: 250,
                transport: (params, success, failure) => {
                    let url = 'form/distinct/' + this.options.modelId + '/' + 'tags';

                    if (params && params.data) {
                        url += '?' + $.param(params.data);
                    }

                    this.client.get(url)
                        .then(data => success(data))
                        .catch(error => failure(error))
                }
            };

            //Distinct values

        }

        $(this.select).select2(options);

        this.valueChanged();
    }

    valueChanged() {
        $(this.select).val(this.value).trigger('change.select2');
    }

    change(e) {
        let newValue = $(e.currentTarget).val();

        if (newValue === '') {
            newValue = [];
        }

        this.value = newValue;
    }
}

import {bindable, LogManager, customElement} from 'aurelia-framework';

import "./images-choice-element.less";

const logger = LogManager.getLogger('ImagesChoiceElement');

@customElement('sio-images-choice-input')
export class ImagesChoiceElement
{
    @bindable config;
    @bindable({defaultBindingMode: 2}) value;
}
